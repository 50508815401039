@import "variables.scss";

#sound-icons {
  position: fixed;
  bottom: 10px;
  left: 20px;
  // transform: translate(-50%, -50%);
  z-index: 6;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
}

@media (max-width: #{$breakpoint-mobile}) {
  #sound-icons {
    bottom: 5px;
    left: 15px;
  }
}
