@import "variables.scss";

#contact {
  display: flex;
  width: 100%;
  flex-direction: column;
  // justify-content: flex-end;
  align-items: center;
  font-size: 13px;
  font-weight: 400;

  & > div {
    width: 100%;
    flex: auto;
    display: flex;

    form {
      text-transform: uppercase;
      display: flex;
      justify-self: center;
      flex-direction: column;
      justify-content: center;
      width: unquote("clamp(320px, 95vw, 700px)");
      margin: 1em auto;
      padding: 1em 2em;
      border-radius: 4px;
      background-color: rgba($color: #000, $alpha: 0.8);

      p {
        align-self: center;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 4px;
      }

      #captcha {
        div {
          align-items: center;
          margin: 0;
        }
      }

      div {
        margin-bottom: 1.2em;
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 5px;
        }
      }

      #submit-button {
        align-self: center;
        margin-bottom: 0;

        width: 160px;

        button {
          padding: 10px 0 5px 0;
          font-weight: 500;
          font-size: 15px;
          cursor: pointer;
          background-color: #000;
          color: white;
          border: solid 1px white;
          border-radius: 2px;
          transition: all 0.3s ease-in;
        }

        button:hover {
          background-color: white;
          color: #000;

          transition: all 0.3s ease-in;
        }
      }
    }

    &[data-visible="true"] {
      transform: scale(1);
    }

    &[data-visible="false"] {
      transform: scale(0);
    }

    transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  footer {
    width: 100%;
    height: unquote("max(10vh, 100px)");
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      display: block;
      // flex: auto;
      height: 1px;
      top: 0;
      background-color: white;
      width: 75%;
      left: 12.5%;
    }

    p {
      margin: 0;

      &:first-child {
        font-size: 16px;
        font-weight: 300;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 4px;
      }

      &:last-child {
        margin: 0 20px 20px 20px;
        font-size: 13px;
        font-weight: 300;
        text-align: center;

        a {
          color: #fff;
          font-weight: 400;

          text-underline-offset: 2px;
        }
      }
    }
  }
}

@media (max-width: #{$breakpoint-mobile}) {
  #contact {
    & > div {
      form {
        p {
          font-size: 15px;
        }

        div {
          margin-bottom: 1em;

          label {
            margin-bottom: 2px;
            font-size: 12px;
          }
        }

        #captcha {
          div {
            align-items: center;
          }
        }
      }
    }

    footer {
      p {
        &:first-child {
          font-size: 12px;
          font-weight: 400;
          margin-top: 10px;
          margin-bottom: 0;
        }

        &:last-child {
          font-size: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .component:last-child {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
