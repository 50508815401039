@import "variables.scss";

.component {
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index: 5;
  overflow: hidden;
}

.subcomponent {
  height: 100vh;
  width: 100vw;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.page-controls {
  #hamburguer {
    position: fixed;
    z-index: 5;
    right: 20px;
    top: 5px;
    cursor: pointer;

    path {
      transform-origin: center;

      transition: all 0.3s ease-in 0s;
    }

    &[data-close="yes"] path {
      fill: black;

      &:first-child {
        transform: rotate(21deg);
      }

      &:last-child {
        transform: rotate(-21deg);
      }

      transition: all 0.3s ease-in 0s;
    }

    &[data-close="no"]:hover path {
      transform-origin: center;

      &:first-child {
        transform: rotate(-21deg);
      }

      &:last-child {
        transform: rotate(21deg);
      }

      transition: all 0.3s ease-out 0s;
    }
  }
}

.menu-enter {
  transform: translateX(600px);
  opacity: 0.2;
}

.menu-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.4s ease-in;
}

.menu-exit {
  transform: translateX(0);
  opacity: 1;
}

.menu-exit-active {
  transform: translateX(600px);
  opacity: 0.2;
  transition: all 0.4s ease-in;
}

.menu {
  position: fixed;
  z-index: 4;
  height: 100vh;
  width: unquote("clamp(320px, 50vw, 500px)");
  right: 0;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 1em;
  font-size: 25px;
  letter-spacing: 1px;
  color: black;
  font-weight: 300;

  button {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    padding: 5px 5px;
    margin: 1em;
    display: inline-block;
    position: relative;
    letter-spacing: 1px;

    &:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      position: absolute;
      background: #000;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    &:hover:after {
      width: 100%;
      left: 0;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        -webkit-animation: slide-in-right
          0.8s
          cubic-bezier(0.25, 0.46, 0.45, 0.94)
          #{$i *
          0.1}s;
        animation: slide-in-right
          0.8s
          cubic-bezier(0.25, 0.46, 0.45, 0.94)
          #{$i *
          0.1}s;

        animation-fill-mode: both;
      }
    }
  }

  .active {
    font-weight: 600;
    cursor: alias;
  }

  #curriculum {
    position: absolute;
    bottom: 30px;

    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: #000;

    &:after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      position: absolute;
      background: #000;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    &:hover:after {
      width: 100%;
      left: 0;
    }
  }
}

#socials {
  position: fixed;
  z-index: 5;
  right: 30px;
  bottom: 30px;
  // transform: translateY(-50%);

  display: flex;
  flex-direction: column;

  a {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  .page-controls {
    #hamburguer {
      top: -8px;
      right: 2px;
    }
  }
}

@media (max-width: #{$breakpoint-mobile}) {
  .menu {
    font-size: 20px;

    .active {
      font-weight: 300;
      cursor: alias;
    }

    #curriculum {
      font-size: 14px;
    }
  }

  #socials {
    right: 20px;
  }
}

/* ----------------------------------------------
* Generated by Animista on 2021-3-8 19:12:19
* Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
