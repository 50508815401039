@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import "variables.scss";

#about {
  font-size: 50px;
  font-weight: 200;
  margin: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  line-height: 70px;

  #cs,
  #se {
    font-weight: 400;
    text-transform: uppercase;
  }

  #pt {
    letter-spacing: 6px;
  }

  #db {
    letter-spacing: 4px;
    font-weight: 300;
    display: inline-flex;
    margin: 0 0 0 15px;
    span {
      float: left;
      transform: rotate(20deg);
    }
  }

  #bd {
    font-weight: 100;
    text-transform: uppercase;
  }

  #dg {
    font-family: "Abril Fatface", cursive;
  }

  .w,
  .d {
    display: inline;
    position: relative;
  }

  .w {
    margin-right: 10px;
  }

  .w:after {
    content: "web";
  }

  .d:after {
    content: "development";
  }

  .w:after,
  .d:after {
    position: absolute;
    display: inline;
    left: 5px;
    bottom: -7px;
  }

  .dv {
    letter-spacing: 4px;
    font-weight: 300;
    display: inline-flex;
    margin: 0 0 0 10px;
    span:nth-child(2n) {
      float: left;
      transform: translateY(5px);
    }
    span:nth-child(2n + 1) {
      float: left;
      transform: translateY(-5px);
    }
  }

  #new {
    display: inline-flex;

    span:first-child {
      float: left;
      transform: rotate(-30deg);
    }

    span:nth-child(2) {
      float: left;
      transform: translate(3px, -10px);
    }

    span:last-child {
      float: left;
      transform: rotate(30deg);
    }
  }

  div:nth-child(odd) p {
    text-align: end;
  }

  div p {
    &[data-visible="true"] {
      transform: translateY(0px);
    }

    &[data-visible="false"] {
      transform: translateY(500px);
    }
  }

  div {
    overflow: hidden;
  }

  p {
    margin: 0;
    overflow: hidden;
    transition: all 0.8s ease-out;
    line-height: 70px;
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  #about {
    font-size: 30px;

    p {
      line-height: 60px;
    }

    .w:after,
    .d:after {
      left: 3px;
      bottom: -13px;
    }

    .dv {
      span:nth-child(2n) {
        transform: translateY(3px);
      }
      span:nth-child(2n + 1) {
        transform: translateY(-3px);
      }
    }

    #new {
      span:nth-child(2) {
        transform: translate(2px, -8px);
      }
    }
  }
}

@media (max-width: #{$breakpoint-mobile}) {
  #about {
    font-size: 20px;

    p {
      line-height: 35px;
    }

    .w:after,
    .d:after {
      left: 2px;
      bottom: -7px;
    }

    .dv {
      span:nth-child(2n) {
        transform: translateY(2px);
      }
      span:nth-child(2n + 1) {
        transform: translateY(-2px);
      }
    }

    #new {
      span:nth-child(2) {
        transform: translate(1px, -5px);
      }
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-8 10:18:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
