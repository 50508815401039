@import "variables.scss";

#home {
  width: 100%;

  #name-title {
    font-family: "Advent Pro";
    font-size: 120px;
    font-weight: 100;
    letter-spacing: 25px;
    word-spacing: -40px;
    margin: 35vh 0 0 5vw;
    line-height: 65px;

    strong {
      font-weight: 400;
    }

    &[data-visible="true"] {
      letter-spacing: 25px;
      opacity: 1;
    }

    &[data-visible="false"] {
      letter-spacing: -0.5em;
      opacity: 0;
    }

    transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  #scroll-reminder {
    font-family: "Spartan";
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 2px;

    position: absolute;
    bottom: 2%;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 4px;
    }

    svg {
      -webkit-animation: shake-vertical 1s linear infinite both;
      animation: shake-vertical 1s linear infinite both;
    }
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  #home {
    #name-title {
      font-size: 75px;
    }
  }
}

@media (max-width: #{$breakpoint-mobile}) {
  #home {
    #name-title {
      margin: 35vh 0 0 10vw;
      font-size: 55px;
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-26 1:40:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
@-webkit-keyframes shake-vertical {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  75% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
}
@keyframes shake-vertical {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  75% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
