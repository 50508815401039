#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#scene {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-color: rgb(15, 15, 15);
  background-color: radial-gradient(
    circle,
    rgba(15, 15, 15, 1) 0%,
    rgba(10, 10, 10, 1) 25%,
    rgba(5, 5, 5, 1) 50%,
    rgba(0, 0, 0, 1) 100%
  );
}
