@import "variables.scss";

#experience {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 5%;

  div {
    overflow-y: hidden;

    &:first-child {
      align-self: flex-start;
    }

    &:nth-child(2) {
      align-self: flex-end;
    }

    &:last-child {
      text-align: center;
    }

    p {
      font-size: 40px;
      font-weight: 300;
      max-width: 65vw;
      margin: 0;

      overflow-y: hidden;
      transition: all 0.8s ease-out;
      line-height: 70px;

      &[data-visible="true"] {
        transform: translateY(0px);
      }

      &[data-visible="false"] {
        transform: translateY(500px);
      }
    }
  }

  #empty {
    font-weight: 100;
    letter-spacing: 4px;
  }

  #you {
    text-transform: uppercase;
    font-weight: 400;
  }

  #contact-me {
    border-bottom: 1px solid white;
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  #experience {
    div p {
      font-size: 30px;
      max-width: 80vw;
      line-height: 60px;
    }
  }
}

@media (max-width: #{$breakpoint-mobile}) {
  #experience {
    div p {
      font-size: 20px;
      max-width: 90vw;
      line-height: 35px;
    }
  }
}
