@import "variables.scss";

.project-card {
  display: flex;
  height: unquote("calc(50% - 1.5em)");
  width: 90%;
  flex-wrap: nowrap;
  background-color: rgba($color: #000, $alpha: 0.8);
  border-radius: 2px;
  margin: 1em;

  img {
    align-self: flex-start;
    height: auto;
    margin: auto;
    max-height: 100%;
    width: 50vw;
    max-width: 700px;

    filter: brightness(0.8);
  }

  &:nth-child(odd) {
    align-self: flex-start;
    flex-direction: row;
    margin-bottom: 0.5em;

    &[data-visible="true"] {
      transform: translateX(0px);
    }

    &[data-visible="false"] {
      transform: translateX(-2000px);
    }
  }

  &:nth-child(even) {
    align-self: flex-end;
    flex-direction: row-reverse;
    margin-top: 0.5em;

    &[data-visible="true"] {
      transform: translateX(0px);
    }

    &[data-visible="false"] {
      transform: translateX(2000px);
    }
  }

  transition: all 1s ease-out;

  .information {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1em 2em;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;

    strong {
      font-weight: 500;
    }

    .description {
      h4 {
        font-size: 26px;
        font-weight: 400;
        letter-spacing: 2px;
      }

      p {
        margin: 0.5em 0 0 0;
      }
    }

    .technologies {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .icon {
        width: fit-content;
        height: fit-content;
        cursor: pointer;

        svg {
          width: 35px;
          max-height: 35px;
          margin-left: 5px;

          path {
            fill: white;
          }
        }

        span {
          display: none;
          font-size: 12px;
          font-weight: 600;
          z-index: 100;
          position: absolute;
          padding: 2px 8px 0 8px;
          border-radius: 1px;
          transform: translateX(-25%);
          color: #000;
          background-color: white;
        }

        &:hover {
          span {
            display: block;
          }
        }
      }
    }

    .buttons {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
      width: unquote("clamp(200px, 100%, 450px)");

      a {
        padding: 6px 30px 2px 30px;
        font-weight: 400;
        font-size: 13px;
        text-decoration: none;
        cursor: pointer;
        background-color: rgba($color: #000, $alpha: 0.6);
        color: white;
        border: solid 1px white;
        border-radius: 20px;
        transition: all 0.3s ease-in;
      }

      a:hover {
        background-color: white;
        color: #000;

        transition: all 0.3s ease-in;
      }
    }
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  .project-card {
    width: 100%;

    .information {
      font-size: 13px;

      .description {
        h4 {
          font-size: 20px;
        }
      }

      .buttons {
        a {
          padding: 4px 25px 1px 25px;
          font-size: 12px;
        }
      }

      .technologies {
        .icon {
          svg {
            width: 28px;
            max-height: 28px;
            margin-left: 4px;
          }

          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: #{$breakpoint-mobile}) {
  .subcomponent {
    &:nth-child(even) {
      .project-card {
        &[data-visible="true"] {
          transform: translateX(0px);
        }

        &[data-visible="false"] {
          transform: translateX(-2000px);
        }
      }
    }

    &:nth-child(odd) {
      .project-card {
        &[data-visible="true"] {
          transform: translateX(0px);
        }

        &[data-visible="false"] {
          transform: translateX(2000px);
        }
      }
    }
  }

  .project-card {
    margin: 0 !important;
    height: 100vh;
    flex-direction: column !important;
    align-items: center;

    img {
      width: 95%;

      margin: 5% auto;
    }

    .information {
      flex: auto;
      font-size: 11px;
      padding: 0 1.5em;

      .description {
        h4 {
          font-size: 18px;
        }
      }

      .buttons {
        a {
          padding: 4px 25px 1px 25px;
          font-size: 12px;
        }
      }

      .technologies {
        .icon {
          svg {
            width: 28px;
            max-height: 28px;
            margin-left: 4px;
          }

          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
