@import "variables.scss";

.loading-screen,
.selection-screen {
  position: fixed;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 50;
  flex-direction: column;

  h3 {
    margin: 0 auto 30px auto;
    font-size: 25px;
    font-weight: 200;
    letter-spacing: 1px;

    @for $i from 1 through 30 {
      span:nth-child(#{$i}) {
        animation: wave 2.5s linear infinite;
        animation-delay: #{$i * 0.1}s;
        animation-fill-mode: both;
      }
    }

    span,
    div {
      display: inline-block;
    }

    div div {
      width: 8px;
    }
  }

  #progress-bar {
    width: unquote("min(300px, 80vw)");
    height: 2px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    background-color: #222;

    #filled {
      height: 100%;
      background-color: white;

      transition: all 0.5s linear;
    }
  }

  #mode-buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: unquote("clamp(200px, 100%, 450px)");

    button {
      text-transform: uppercase;
      padding: 10px 50px 4px 50px;
      font-weight: 400;
      font-size: 14px;
      text-decoration: none;
      cursor: pointer;
      background-color: rgba($color: #000, $alpha: 0.6);
      color: white;
      border: solid 1px white;
      border-radius: 3px;
      transition: all 0.3s ease-in;

      &:first-child {
        margin-right: 35px;
      }
    }

    button:hover {
      background-color: white;
      color: #000;

      transition: all 0.3s ease-in;
    }
  }

  p {
    margin-top: 20px;
    width: unquote("clamp(300px, 90%, 600px)");
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 1px;
  }
}

.selection-screen {
  padding-top: 10vh;
}

@media (max-width: #{$breakpoint-mobile}) {
  .loading-screen,
  .selection-screen {
    h3 {
      font-size: 20px;
    }

    #mode-buttons {
      button {
        padding: 6px 40px 2px 40px;
        font-size: 12px;

        &:first-child {
          margin-right: 15px;
        }
      }
    }

    p {
      font-size: 11px;
    }
  }

  .selection-screen {
    padding-top: 25vh;
  }
}

@-webkit-keyframes wave {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  15% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes wave {
  0%,
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
